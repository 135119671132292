import React from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { getGatsbyImageData } from 'gatsby-source-sanity'

export const sanityConfig = { projectId: 'j074tfba', dataset: 'live' }

export const serializers = {
  types: {
    image: ({ node }) => {
      const imageData = getGatsbyImageData(
        node.asset,
        {
          maxWidth: 1024,
        },
        sanityConfig
      )
      return <GatsbyImage image={imageData} alt={node.alt} placeholder="blurred" />
    },
  },
  marks: {
    mark: ({ children }) => <mark>{children}</mark>,
    link: ({ children, mark }) =>
      mark.href.startsWith('/') ? (
        <Link to={mark.href}>{children}</Link>
      ) : (
        <a href={mark.href} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      ),
  },
}

export const exitTransition = {
  length: 0.15, // Take x seconds to leave
  // trigger: () => console.log('We are exiting'),
}

export const entryTransition = {
  delay: 0.15, // Wait x seconds before entering
  // trigger: () => console.log('We are entering'),
}

export const fragment = graphql`
  fragment ResourceData on SanityResources {
    title
    description
    url
    id
  }
`
export const positionFelix = (link) => {
  const felix = document.getElementById('mr-felix')
  if (link && felix) {
    const linkBounding = link.getBoundingClientRect()
    const navBounding = link.parentElement.getBoundingClientRect()
    const left = linkBounding.left - navBounding.left
    felix.style.left = `${left}px`
  }
}
